<template>
  <v-card-text>
    <v-row
    >
      <v-col cols="1" offset-md="1">
        <v-checkbox class="ml-4" label="Enable" v-model="thisEnabled" @change="updateApi"></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
            @change="updateApi"
            :disabled="loading || !thisEnabled"
            class="text-center"
            :items="zones"
            label="Timezone"
            v-model="tz"
        ></v-autocomplete>
        {{ tzTime }}
      </v-col>
      <v-col cols="2">
        <v-select
            :disabled="loading || !thisEnabled"
            class="text-center"
            :items="templates"
            label="Template"
            v-model="template"
            return-object
        ></v-select>
        <!--        <v-btn class="mt-4" color="green" :disabled="!validateSave()">Save-->
        <!--          <v-icon>mdi-content-save</v-icon>-->
        <!--        </v-btn>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-progress-linear class="mb-6" v-if="loading"
                             indeterminate
                             rounded
                             height="6"
          ></v-progress-linear>
        </v-row>
        <v-row v-for="(d, i) in [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]"
               :key="d+i" class="ma-0 pa-0">
          <v-col cols="12" md="1" class="pa-0 ma-0">

            <p class="subtitle-1">
              {{ d }}
            </p>
          </v-col>
          <v-col cols="12" md="11" class="pa-0 ma-0">
            <v-btn-toggle rounded multiple v-model="thisSchedule[i]" :disabled="loading || !thisEnabled">
              <v-btn v-for="h in scheduleItems[i]" :key="i + '/' +h" small :disabled="loading || !thisEnabled"
                     @change="updateApi">
                {{ h }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

      </v-col>


    </v-row>
  </v-card-text>
</template>

<script>
import { DateTime } from 'luxon'
import api from '@/utils/api'

export default {
  name: 'ScheduleEditor',
  props: {
    id: {
      default: 0
    },
    value: {
      default: 0
    },
    schedule: {
      default: '{}'
    }
  },
  data () {
    return {
      thisEnabled: this.value,
      tz: 'UTC',
      loading: false,
      scheduleItems: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      ],
      parsedSchedule: [],
      thisSchedule: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      ],
      template: null,
      templates: [
        {
          text: 'Always',
          value: 'Always',
          schedule: [
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
          ]
        },
        {
          text: 'Working day',
          value: 'Working day',
          schedule: [
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [],
            [],
          ]
        },
        {
          text: 'Working day and Weekends',
          value: 'Working day and Weekends',
          schedule: [
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
          ]
        },
        {
          text: 'Working day and Weekends + Evening',
          value: 'Working day and Weekends + evening',
          schedule: [
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
          ]
        },
        {
          text: 'Weekends',
          value: 'Weekends',
          schedule: [
            [],
            [],
            [],
            [],
            [],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          ]
        },
        {
          text: 'Weekends Working',
          value: 'Weekends Working',
          schedule: [
            [],
            [],
            [],
            [],
            [],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
          ]
        }],
      tzTime: ''
    }
  },
  created () {
    // console.log(this.enabled)
    // console.log('this.schedule', this.schedule)
    if (typeof this.schedule === 'object') {
      if (this.schedule && 'week' in this.schedule) {
        this.thisSchedule = this.schedule.week
        this.tz = this.schedule.tz
      }
    } else {
      if (this.schedule !== '') {
        try {
          const r = JSON.parse(this.schedule)
          if (r && 'week' in r) {
            this.thisSchedule = r.week
            this.tz = r.tz
          }
        } catch (e) {
          console.log(e)
        }
      }
    }

    setInterval(() => {
      this.tzTime = DateTime.fromObject({zone: this.tz}).toRFC2822()
    }, 1000)
  },
  computed: {
    onModeration: {
      get () {
        return this.$store.getters['iface/isDspTeasersOnModeration']
      }
    },
    zones () {
      return [{
        text: 'UTC +0',
        value: 'UTC'
      }].concat(Intl.supportedValuesOf('timeZone').map(zone => ({
        text: zone + ' ' + DateTime.local().setZone(zone).toFormat('Z'),
        value: zone
      })))
    },

    // tzTime() {
    //   return DateTime.fromObject({zone: this.tz}).toRFC2822()
    // }
  },

  methods: {
    async updateApi () {
      // console.log('updateApi', {
      //   networkName: this.$route.params.section,
      //   id: this.id,
      //   enable: this.thisEnabled,
      //   tz: this.tz,
      //   schedule: {week: this.thisSchedule}
      // })
      this.loading = true
      //dspScheduleUpdate
      try {
        await api.dspScheduleUpdate({
          networkName: this.$route.params.section,
          onModeration: Boolean(this.onModeration),
          id: this.id,
          enable: this.thisEnabled,
          tz: this.tz,
          schedule: {week: this.thisSchedule}
        })
        // console.info('dspScheduleUpdate res', res)
        this.loading = false
        this.$emit('input', this.thisEnabled)
        this.$emit('change', true)
      } catch (e) {
        // console.error('dspScheduleUpdate', e)
        this.loading = false
      }
    }
  },
  watch: {
    template (value) {
      if (value !== null) {
        this.thisSchedule = this.thisSchedule.map((e, i) => value.schedule[i].slice())
        this.$nextTick(() => this.updateApi())
      }
    }
  }
}
</script>

<style scoped>

</style>